import { grey, spacing, Tooltip, Container } from '@pelotoncycle/design-system';
import React from 'react';

type Props = {
  dataTestId: string;
};

const CartTooltip: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  dataTestId,
}) => (
  <Tooltip
    thin
    trigger="hover"
    position="bottom"
    icon={{ primaryColor: grey[70] }}
    margin={`${spacing[4]} 0 0 ${spacing[8]}`}
    data-test-id={dataTestId}
  >
    <Container textAlign="center" padding={spacing[12]}>
      {children}
    </Container>
  </Tooltip>
);

export default CartTooltip;
