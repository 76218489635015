import { grey, Support } from '@pelotoncycle/design-system';
import React from 'react';
import CartContent from '@ecomm/cart-next/shared/CartContent';
import AffirmCopyWithLogo from '@ecomm/financing/affirm/AffirmCopyWithLogo';
import { CitizensCopyVariation } from '@ecomm/financing/citizens/CitizensCopy';
import { Partners } from '@ecomm/financing/models/Partners';
import TruemedCopy from '@ecomm/financing/truemed/TruemedCopy';
import ZipLogoModule from '@ecomm/financing/zip/ZipLogoModule';
import type { BundleType } from '@ecomm/shop/models/BundleType';
import useGetCartFinancingInformation from '../../hooks/useGetCartFinancingInformation';

type Props = {
  financingPartner: Partners;
  secondaryFinancingPartner?: Partners | null | undefined;
  alternativePaymentPartner?: Partners | null | undefined;
};

interface GetFinancingLinkArgs extends Props {
  cfuBundleSKU: string[];
  bundleType: BundleType;
  term: number;
  total: number;
}

const getAlternativePartner = (alternativePartner: Partners) => {
  switch (alternativePartner) {
    case Partners.Truemed:
      return <TruemedCopy />;
    default:
      return null;
  }
};

const getFinancingLink = ({
  cfuBundleSKU,
  bundleType,
  financingPartner,
  term,
  total,
}: GetFinancingLinkArgs) => {
  switch (financingPartner) {
    case Partners.Zip:
      return <ZipLogoModule bundleType={bundleType} price={total} />;
    case Partners.Affirm: {
      return (
        <AffirmCopyWithLogo
          key={financingPartner}
          term={term}
          cfuBundleSKU={cfuBundleSKU}
          bundleType={bundleType}
          price={total}
          pageType="payment"
        />
      );
    }
    case Partners.Citizens:
      return (
        <CitizensCopyVariation
          key={financingPartner}
          pageType="payment"
          id="citizens-cart-widget"
          price={total}
          bundleType={bundleType}
        />
      );
    default:
      return;
  }
};

const FinancingSupportContainer: React.FC<React.PropsWithChildren<Props>> = ({
  financingPartner,
  secondaryFinancingPartner,
  alternativePaymentPartner,
}) => {
  const {
    financingBundleType,
    financingTerm,
    cartTotal,
    monthlyTotalInDollars,
    cfuBundleSKU,
  } = useGetCartFinancingInformation(financingPartner);

  const showSecondaryFinancingPartner = !!secondaryFinancingPartner;

  return (
    <Support size="large" textColor={grey[70]}>
      <CartContent
        field="financingPricing"
        values={{
          monthlyTotal: monthlyTotalInDollars,
          term: financingTerm,
        }}
      />
      {getFinancingLink({
        financingPartner,
        bundleType: financingBundleType,
        total: cartTotal,
        term: financingTerm,
        cfuBundleSKU,
      })}
      {showSecondaryFinancingPartner &&
        getFinancingLink({
          financingPartner: secondaryFinancingPartner,
          bundleType: financingBundleType,
          total: cartTotal,
          term: financingTerm,
          cfuBundleSKU,
        })}
      {alternativePaymentPartner && getAlternativePartner(alternativePaymentPartner)}
    </Support>
  );
};

export default FinancingSupportContainer;
