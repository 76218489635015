import { useLocale } from '@peloton/internationalize/locales';
import useGetCartItems from '@ecomm/commercetools/hooks/useGetCartItems';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import {
  roundByFinancingPartner,
  roundDollarsByFinancingPartner,
} from '@ecomm/financing/models/monthlyPaymentWithApr';
import type { Partners } from '@ecomm/financing/models/Partners';
import {
  financingTermFromCart,
  financingBundleTypeFromCart,
} from '@ecomm/financing/models/term';
import { useApr } from '@ecomm/pricing/helpers/useApr';
import { getCartTotal, getCFUBundleSKU } from '../helpers/ct-cart-helper';
import { useGetCfuTypesInCTCart } from './useGetCfuTypesInCTCart';

const useGetCartFinancingInformation = (financingPartner: Partners) => {
  const { data: shopCart } = useGetCartItems();
  const {
    hasBike: hasBikeInCart,
    hasBikePlus: hasBikePlusInCart,
    hasTread: hasTreadInCart,
    hasTreadPlus: hasTreadPlusInCart,
    hasRow: hasRowInCart,
    hasGuide: hasGuideInCart,
  } = useGetCfuTypesInCTCart();
  const locale = useLocale();
  const isZipThirtySixMonthsFinancingEnabled = useIsToggleActive()(
    'zipThirtySixMonthFinancing',
  );

  const financingTerm = financingTermFromCart(
    {
      partner: financingPartner,
      hasBikeInCart,
      hasBikePlusInCart,
      hasTreadInCart,
      hasTreadPlusInCart,
      hasGuideInCart,
      hasRowInCart,
    },
    isZipThirtySixMonthsFinancingEnabled,
  );
  const financingBundleType = financingBundleTypeFromCart({
    hasBikeInCart,
    hasBikePlusInCart,
    hasTreadInCart,
    hasTreadPlusInCart,
    hasGuideInCart,
    hasRowInCart,
  });
  const cartTotal = getCartTotal(shopCart);
  const monthlyTotalInCents = roundByFinancingPartner(
    cartTotal,
    financingTerm,
    useApr(locale, financingBundleType, financingTerm),
    financingPartner,
  );
  const monthlyTotalInDollars = roundDollarsByFinancingPartner(
    monthlyTotalInCents,
    financingPartner,
  );
  const cfuBundleSKU = getCFUBundleSKU({ shopCart, financingBundleType });

  return {
    financingBundleType,
    financingTerm,
    cartTotal,
    monthlyTotalInDollars,
    cfuBundleSKU,
  };
};

export default useGetCartFinancingInformation;
