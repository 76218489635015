import { grey, spacing, black } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import { toDollars } from '@ecomm/models';
import { BundleType } from '@ecomm/shop/models';
import type { PageType } from '../affirm/AffirmCopy';
import { Partners } from '../models';
import { trackClick } from '../utils/tracking';
import { getCitizens } from './api';

const citizensStyles = {
  'citizens-checkout-widget': {
    container_style: {
      marginLeft: spacing[2],
      display: 'inline',
    },
    link_style: {
      color: black,
      textDecoration: 'underline',
    },
  },
  'citizens-default-widget': {
    link_style: { color: grey[70], textDecoration: 'underline' },
    container_style: { paddingTop: spacing[8], paddingBottom: 0 },
  },
  'citizens-financing-widget': {
    link_style: { color: black },
    container_style: {
      padding: 0,
    },
  },
};

const getCategory = (bundleType: BundleType) => {
  switch (bundleType) {
    case BundleType.Bike:
    case BundleType.RefurbishedBike:
      return 'PELOTONBIKE';

    default:
      return 'PELOTONCFU';
  }
};

const CitizensCopy: React.FC<
  React.PropsWithChildren<{
    id: string;
    className?: string;
    text?: string;
    price: number;
    pageType: PageType;
    bundleType: BundleType;
  }>
> = ({
  id,
  className = 'citizens-default-widget',
  text = 'Check eligibility with Citizens Pay®',
  price,
  pageType,
  bundleType,
}) => {
  const citizensProvider = useWaitForCitizensSdk();
  const cpayPrequalifyEnabled = useIsToggleActive()('cpayPrequalify');
  React.useEffect(() => {
    if (citizensProvider && cpayPrequalifyEnabled) {
      citizensProvider.init(
        id,
        {
          price: toDollars(price),
          link_behavior: 0,
          link_text: text,
          flow: 'Prequal',
          sourcepage: 'PIP',
          text: '',
          ...citizensStyles[className],
        },
        getCategory(bundleType),
      );
    }
  }, [id, className, price, text, citizensProvider, cpayPrequalifyEnabled, bundleType]);

  return (
    // eslint-disable-next-line styled-components-a11y/click-events-have-key-events,styled-components-a11y/no-static-element-interactions
    <StyledCitizensCopy
      id={id}
      className={className}
      data-test-id={id}
      onClick={trackClick(Partners.Citizens, bundleType, pageType, price)}
    ></StyledCitizensCopy>
  );
};

const useWaitForCitizensSdk = () => {
  const [sdk, setSdk] = React.useState<Record<string, any> | null>(null);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (getCitizens()?.key) {
        setSdk(getCitizens());
        clearInterval(interval);
      }
    }, 10);

    return () => clearInterval(interval);
  }, [sdk]);

  return sdk;
};

export default CitizensCopy;

const StyledCitizensCopy = styled.div`
  #jifitiCurrencyWrapper,
  #jifitiCheckmark {
    display: none !important;
  }

  #addLinkToJifitiWidget {
    display: inline !important;
    margin-left: ${spacing[4]} !important;
  }

  #jifitiIntroText,
  #addLinkToJifitiWidget {
    font-family: inherit !important;
    font-size: inherit !important;
    font-weight: inherit !important;
    color: inherit !important;
    line-height: inherit !important;
    letter-spacing: inherit !important;
    padding: 0 !important;
    margin-bottom: 0 !important;
    background: none !important;
  }
`;

export const CitizensCopyVariation = styled(CitizensCopy)`
  a {
    text-underline-offset: 4px;
    color: ${grey[90]} !important;
    font-weight: 400 !important;
    font-size: 13px;
    letter-spacing: 0.1px;
    line-height: ${spacing[16]};
  }
`;
