import { Support, slate, Container } from '@pelotoncycle/design-system';
import React from 'react';
import CartContent from '@ecomm/cart-next/shared/CartContent';

const Vat: React.FC = () => {
  return (
    <Container>
      <Support size="medium" textColor={slate[50]}>
        <CartContent field="vatIncludedLabel" />
      </Support>
    </Container>
  );
};

export default Vat;
