import { toLocaleFromHostname } from '@peloton/internationalize';
import { toCurrency } from '@peloton/internationalize/models';
import { ShopCartUpdateActionType } from '@ecomm/graphql/types.generated';
import type {
  CountryCode,
  ShopCart,
  ShopCartDraft,
  ShopCartUpdateAction,
} from '@ecomm/graphql/types.generated';
import type {
  AccessoryBundleCommercetools,
  ProductItemOfBundle,
} from '@ecomm/pg-shop-accessories-display/models';
import type { CtCartFragment } from '@ecomm/shop-cart/graphql/fragments.generated';
import type { GetShopCartJupiterQuery } from '@ecomm/shop-cart/graphql/queries/ShopCart.generated';
// NOTE: we can use ShopCartDraft when the key prop has been deprecated on Shop Platform
export type ShopCartDraftWithoutKey = Omit<ShopCartDraft, 'key'>;

export type CartItems = GetShopCartJupiterQuery | undefined;

type SelectedOption = {
  attribute?: string;
  option?: string;
  sku?: string;
};
type ProductSelection = {
  product: string;
  selections: Array<SelectedOption> | Array<null>;
};
type BundleItem = {
  sku: string;
  quantity: number;
};

const callUpdateCartMutation = async (
  actions: Array<ShopCartUpdateAction>,
  country: CountryCode,
  currentCart: CtCartFragment | undefined | null,
  updateCartMutation: Function,
  createCartMutation?: Function,
): Promise<ShopCart> => {
  let cart = null;
  if (createCartMutation && !currentCart) {
    cart = callCreateCartMutation(actions, country, createCartMutation);
  } else {
    cart = await updateCartMutation({
      variables: {
        input: {
          country: country,
          actions,
        },
      },
    });
  }

  return cart;
};

const callCreateCartMutation = async (
  actions: Array<ShopCartUpdateAction>,
  country: CountryCode,
  createCartMutation: Function,
): Promise<ShopCart> => {
  const locale = toLocaleFromHostname();
  const input: ShopCartDraftWithoutKey = {
    currencyCode: toCurrency(),
    lineItems: [],
    locale,
    country,
    customerEmail: null,
    shippingAddress: null,
    billingAddress: null,
    discounts: [],
  };

  actions.forEach((action: ShopCartUpdateAction) => {
    switch (action.actionType) {
      case ShopCartUpdateActionType.AddLineItem:
        if (action.addLineItem) input.lineItems.push(action.addLineItem);
        break;
      case ShopCartUpdateActionType.AddBundleItem:
        if (action.addBundleItem) {
          action.addBundleItem['quantity'] = 1;
          input.lineItems.push(action.addBundleItem);
        }
        break;
      case ShopCartUpdateActionType.SetCustomerEmail:
        if (action.setCustomerEmail)
          input.customerEmail = action.setCustomerEmail.customerEmail;
        break;
      case ShopCartUpdateActionType.SetShippingAddress:
        if (action.setShippingAddress)
          input.shippingAddress = action.setShippingAddress.address;
        break;
      case ShopCartUpdateActionType.AddDiscount:
        if (action.addDiscount) input.discounts?.push(action.addDiscount);
        break;
    }
  });

  return await createCartMutation({ variables: { input } });
};

const getSKUs = (
  bundle: AccessoryBundleCommercetools,
  productSelections: ProductSelection[],
) => {
  const skus: string[] = [];

  productSelections.forEach(prod => {
    if (prod?.selections[0]?.sku) skus.push(prod?.selections[0]?.sku);
    else {
      const bundleProduct: any = bundle.products.find(
        (p: ProductItemOfBundle) => p.slug === prod.product,
      );
      if (bundleProduct?.sku) skus.push(bundleProduct.sku);
    }
  });

  return updateQuantities(skus);
};
const updateQuantities = (bundleItemSkus: string[]): BundleItem[] => {
  return bundleItemSkus.reduce((acc, sku) => {
    const index = acc.findIndex(bundleItem => bundleItem.sku === sku);
    if (index === -1) {
      return [...acc, { sku, quantity: 1 }];
    }
    acc[index].quantity = acc[index].quantity + 1;
    return [...acc];
  }, [] as BundleItem[]);
};

export { callUpdateCartMutation, getSKUs };
