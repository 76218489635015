import useGetCartItems from '@ecomm/commercetools/hooks/useGetCartItems';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import type { ShopPaymentPartner } from '@ecomm/graphql/types.generated';
import { PaymentPriority, ShopPaymentType } from '@ecomm/graphql/types.generated';

export const useGetFinancingPaymentPartners = (): ShopPaymentPartner[] => {
  const { data } = useGetCartItems();
  const isTruemedEnabled = useIsToggleActive()('truemed_enabled');
  let availableFinancing: ShopPaymentPartner[] = [];
  if (data && data.paymentPartners) {
    availableFinancing = data.paymentPartners.filter((partner: ShopPaymentPartner) => {
      if (partner.type === ShopPaymentType.TruemedHsaFsa) {
        return isTruemedEnabled;
      }

      return partner.type ? partner.type.includes('Financing') : false;
    });
  }
  const sortedFinancing = availableFinancing.sort((a, b) => {
    if (a.priority === PaymentPriority.Primary && b.priority === PaymentPriority.Backup) {
      return -1;
    }
    if (a.priority === PaymentPriority.Backup && b.priority === PaymentPriority.Primary) {
      return 1;
    }
    return 0;
  });
  return sortedFinancing;
};

export const useIsCreditAvailable = () => {
  const { data } = useGetCartItems();
  if (data && data.paymentPartners) {
    return (
      data.paymentPartners.filter(partner => {
        return partner.type === ShopPaymentType.StripeCreditCard;
      }).length > 0
    );
  }
  return false;
};

export const useIsGiftCardAvailable = () => {
  const { data } = useGetCartItems();
  if (data && data.paymentPartners) {
    return (
      data.paymentPartners.filter(partner => {
        return partner.type === ShopPaymentType.PelotonGiftCard;
      }).length > 0
    );
  }
  return false;
};
