import React from 'react';
import FormatText from '@ecomm/copy/FormatText';
import type { Field } from './useCartContent';
import useCartContent from './useCartContent';

type Props = {
  field: Field;
  values?: Record<string, string | number | boolean>;
};

const CartContent: React.FC<React.PropsWithChildren<Props>> = ({ field, values }) => (
  <FormatText content={useCartContent(field)} values={values} />
);

export default CartContent;
