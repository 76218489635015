import { DEFAULT_MARKETING_OPT_IN_STATUS_BY_LOCALE } from '@peloton/internationalize';
import type { Locale } from '@peloton/internationalize/models/locale';
import type { CtCartFragment } from '@ecomm/shop-cart/graphql/fragments.generated';

export const isValidatedAddress = (data: CtCartFragment): boolean => {
  if (!data.shippingAddress) {
    return false;
  }
  return data.shippingAddress.isValidated;
};

export const getCartId = (data: CtCartFragment): string => {
  if (!data || !data.id) {
    return '';
  }
  return data.id;
};

export const getExistingMarketingOptInStatus = (
  locale: Locale,
  shopCart: CtCartFragment | undefined | null,
): boolean => {
  const { shouldAllowMarketing } = shopCart?.customerPreferences ?? {};

  return typeof shouldAllowMarketing === 'boolean'
    ? shouldAllowMarketing
    : DEFAULT_MARKETING_OPT_IN_STATUS_BY_LOCALE[locale];
};
