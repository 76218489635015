import type {
  RentalLocale,
  RentalPrices,
} from '@peloton/internationalize/consolidatedAppConfigs/models';
import { FAAS_LOCALES } from '@peloton/internationalize/consolidatedAppConfigs/models';
import {
  getBikePlusRentalPrice,
  getBikeRentalPrice,
} from '@peloton/internationalize/consolidatedAppConfigs/utils';
import { Locale, toLocaleFromTLD } from '@peloton/internationalize/models/locale';
import { useHasVariation } from '@peloton/split-testing/hooks';
import type { Product, CartNext } from '@ecomm/graphql/types.generated';

export const BIKE_RENTAL_SLUG = 'opc-pkg-7';
export const BIKE_WITH_DELIVERY_RENTAL_SLUG = 'opc-pkg-7-dlv250';
export const BIKE_PLUS_RENTAL_SLUG = 'opc-pkg-bp-8';
export const BIKE_PLUS_WITH_DELIVERY_RENTAL_SLUG = 'opc-pkg-bp-8-250';
export const PREVIOUS_BIKE_PLUS_RENTAL_SLUG = 'opc-pkg-bp-7';

export const INTL_BIKE_RENTAL_SLUG = 'faas-pkg-intl-1';
export const INTL_BIKE_PLUS_RENTAL_SLUG = 'faas-pkg-intl-bp-1';
export const UMICH_BIKE_RENTAL_SLUG = 'faas-pkg-umich';
export const UMICH_BIKE_PLUS_RENTAL_SLUG = 'faas-pkg-umich-bp';

export const RENTAL_SLUGS = [
  BIKE_RENTAL_SLUG,
  BIKE_WITH_DELIVERY_RENTAL_SLUG,
  BIKE_PLUS_RENTAL_SLUG,
  BIKE_PLUS_WITH_DELIVERY_RENTAL_SLUG,
  INTL_BIKE_RENTAL_SLUG,
  INTL_BIKE_PLUS_RENTAL_SLUG,
  PREVIOUS_BIKE_PLUS_RENTAL_SLUG,
  UMICH_BIKE_RENTAL_SLUG,
  UMICH_BIKE_PLUS_RENTAL_SLUG,
];

export const RENTAL_UMICH_SLUGS = [UMICH_BIKE_RENTAL_SLUG, UMICH_BIKE_PLUS_RENTAL_SLUG];

export const RENTALS_SHOP_PATH = '/shop/opc';

export const FAAS_WITH_DELIVERY_EXPERIMENT = 'FaaS with delivery';
export const FAAS_WITH_DELIVERY_VARIATION = 'Variation #1';

export const getRentalSlugs = () => {
  const locale = toLocaleFromTLD();

  switch (locale) {
    case Locale.EnglishUnitedStates:
      return {
        bikeSlug: BIKE_RENTAL_SLUG,
        bikeWithDeliverySlug: BIKE_WITH_DELIVERY_RENTAL_SLUG,
        bikePlusSlug: BIKE_PLUS_RENTAL_SLUG,
        bikePlusWithDeliverySlug: BIKE_PLUS_WITH_DELIVERY_RENTAL_SLUG,
        bikeUMichSlug: UMICH_BIKE_RENTAL_SLUG,
        bikePlusUMichSlug: UMICH_BIKE_PLUS_RENTAL_SLUG,
      };
    case Locale.EnglishCanada:
      return {
        bikeSlug: BIKE_RENTAL_SLUG,
        bikePlusSlug: BIKE_PLUS_RENTAL_SLUG,
      };
    case Locale.GermanGermany:
      return {
        bikeSlug: INTL_BIKE_RENTAL_SLUG,
        bikePlusSlug: INTL_BIKE_PLUS_RENTAL_SLUG,
      };
    case Locale.EnglishUnitedKingdom:
      return {
        bikeSlug: INTL_BIKE_RENTAL_SLUG,
        bikePlusSlug: INTL_BIKE_PLUS_RENTAL_SLUG,
      };
    default:
      return {
        bikeSlug: BIKE_RENTAL_SLUG,
        bikePlusSlug: BIKE_PLUS_RENTAL_SLUG,
      };
  }
};

export const UMICH_BIKES_OPC_DISCOUNT = 10;

export const useRentalPrices = (equipment: string, locale: Locale): RentalPrices => {
  const isFaasWithDeliveryExperimentActive = !!useHasVariation(
    FAAS_WITH_DELIVERY_EXPERIMENT,
    FAAS_WITH_DELIVERY_VARIATION,
  );

  const rentalLocale = locale as RentalLocale;

  if (!FAAS_LOCALES.includes(rentalLocale)) {
    return {
      monthly: 0,
      setup: 0,
    };
  }
  if (equipment === 'BIKE') {
    return getBikeRentalPrice(rentalLocale, isFaasWithDeliveryExperimentActive);
  }
  if (equipment === 'BIKE_PLUS') {
    return getBikePlusRentalPrice(rentalLocale, isFaasWithDeliveryExperimentActive)!;
  }
  return {
    monthly: 0,
    setup: 0,
  };
};

export const isRentalSlug = (slug: string) => RENTAL_SLUGS.includes(slug);

export const isRentalItem = (item: Product) =>
  item.slug.startsWith('opc') || item.slug.startsWith('faas');

export const isUMichBikesOPC = (slug: string | undefined): boolean =>
  slug ? RENTAL_UMICH_SLUGS.includes(slug) : false;

export const hasUMichBikesOPC = (items: CartNext['items']) =>
  items.some((item: any) => isUMichBikesOPC(item.slug));
