export enum MerchantIds {
  Dev = '70a81769-c6c7-49ff-995a-c45c6ea446e8',
  Prod = 'c53a570f-e98d-441c-adb0-2b1878a856ac',
}

export enum ModalEnvironments {
  Dev = 'sandbox',
  Prod = 'production',
}

export const getMerchantId = (isProd: boolean | undefined) =>
  isProd ? MerchantIds.Prod : MerchantIds.Dev;

export const getModalEnvironments = (isProd: boolean | undefined) =>
  isProd ? ModalEnvironments.Prod : ModalEnvironments.Dev;
