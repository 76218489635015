import { Locale } from '@peloton/internationalize';

// Formatters will reformat values into user friendly display strings
// (Formatters are the inverse of Normalizers)
//  ex. phone number
//    2121234567 -> (212) 123-4567

const phoneNumber = (value: string) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 7) {
    return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
  }
  return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
};

const usZipCode = (value: string) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 5) {
    return onlyNums;
  }
  return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 9)}`;
};

// todo: validate/add space in correct position.
const gbZipCode = (value: string) => {
  const val = value
    .toUpperCase()
    .replace(/[^A-Z0-9]/g, '')
    .substr(0, 7);
  if (val.length <= 4) {
    return val;
  }
  if (val.match(new RegExp(/^WC|^EC[1-4]|^NW1W|^SE1P|SW1/))) {
    return `${val.substring(0, 4)} ${val.substring(4)}`;
  }
  if (val.match(new RegExp(/^E1W|^N1[CP]/))) {
    return `${val.substring(0, 3)} ${val.substring(3)}`;
  }
  if (val.match(new RegExp(/^[BEGLMNSW]/))) {
    if (val.length === 5) {
      return `${val.substring(0, 2)} ${val.substring(2)}`;
    }
    return `${val.substring(0, 3)} ${val.substring(3)}`;
  }
  if (val.length === 6) {
    return `${val.substring(0, 3)} ${val.substring(3)}`;
  }
  return `${val.substring(0, 4)} ${val.substring(4)}`;
};

const caZipCode = (value: string) => {
  if (!value) {
    return value;
  }

  if (value.length <= 3) {
    return value;
  }

  return `${value.slice(0, 3)} ${value.slice(3, 6)}`;
};

const deZipCode = (value: string) => {
  if (!value) {
    return value;
  }
  return value.replace(/[^\d]/g, '').slice(0, 5);
};

const atZipCode = (value: string) => {
  if (!value) {
    return value;
  }
  return value.replace(/[^\d]/g, '').slice(0, 4);
};

const auZipCode = (value: string) => {
  if (!value) {
    return value;
  }
  return value.replace(/[^\d]/g, '').slice(0, 4); // ^\\d{4}$
};

const zipCode = {
  [Locale.EnglishUnitedStates]: usZipCode,
  [Locale.EnglishUnitedKingdom]: gbZipCode,
  [Locale.EnglishCanada]: caZipCode,
  [Locale.GermanGermany]: deZipCode,
  [Locale.EnglishAustralia]: auZipCode,
  [Locale.GermanAustria]: atZipCode,
};

const birthday = (input: string) => {
  const DAY_MONTH_DELIMITER = ' / ';
  const MONTH_DAY_YEAR_FORMAT = 'MM / DD / YYYY';
  const DAY_MONTH_YEAR_FORMAT = 'DD / MM / YYYY';
  const MAX_STRING_LENGTH = Math.max(
    MONTH_DAY_YEAR_FORMAT.length,
    DAY_MONTH_YEAR_FORMAT.length,
  );
  // Remove the delimiters and any non digits
  let result = input.replace(DAY_MONTH_DELIMITER, '');
  result = input.replace(/\D/g, '');

  if (result.length > 4) {
    result = result.substr(0, 4) + DAY_MONTH_DELIMITER + result.substr(4);
  }

  if (result.length > 2) {
    result = result.substr(0, 2) + DAY_MONTH_DELIMITER + result.substr(2);
  }

  return result.substr(0, MAX_STRING_LENGTH);
};

const giftCardLast4Numbers = (last4DigitsArray: string[], andCopy: string = 'and') => {
  if (last4DigitsArray.length === 1) {
    return last4DigitsArray[0];
  }
  const firstArgs = last4DigitsArray.slice(0, last4DigitsArray.length - 1).join(', ');
  const lastArg = last4DigitsArray[last4DigitsArray.length - 1];
  return `${firstArgs} ${andCopy} ${lastArg}`;
};

const giftCardNumber = (value: string) => {
  const validateInput = value.replace(/\D/g, '');
  const formattedInput = validateInput.slice(0, 16);
  const formattedWithSpaces = formattedInput.replace(/(\d{4})(?=\d)/g, '$1 ');
  return formattedWithSpaces;
};

const giftCardPinNumber = (value: string) => {
  const validateInput = value.replace(/\D/g, '');
  const formattedInput = validateInput.slice(0, 8);
  const formattedWithSpaces = formattedInput.replace(/(\d{4})(?=\d)/g, '$1');
  return formattedWithSpaces;
};

const serialNumber = (value: string) => {
  const validateInput = value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
  return validateInput.slice(0, 16);
};

export default {
  phoneNumber,
  zipCode,
  birthday,
  giftCardLast4Numbers,
  giftCardNumber,
  giftCardPinNumber,
  serialNumber,
};
