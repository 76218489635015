import React from 'react';
import Markdown from '@peloton/copy/Markdown';
import useCart from '@content/client/www/cart/useCart';

type Props = {
  field: string;
  values?: Record<string, string | number>;
  renderers?: Record<string, React.ElementType>;
};

const CartMarkdownContent: React.FC<React.PropsWithChildren<Props>> = ({
  field,
  values,
  renderers,
}) => {
  const content = useCart().content?.[field]?.value ?? field;
  return <Markdown content={content} values={values} markdown={{ renderers }} />;
};

export default CartMarkdownContent;
