import { spacing, Container, Eyebrow, Support } from '@pelotoncycle/design-system';

import React from 'react';

type Props = {
  title?: string;
  body?: string;
};

const CartMarkdownContent: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  body,
}) => {
  return (
    <Container>
      <Eyebrow size="small" marginBottom={spacing[8]}>
        {title}
      </Eyebrow>
      <Support size="small">{body}</Support>
    </Container>
  );
};

export default CartMarkdownContent;
