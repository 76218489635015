import { slate, spacing, Tooltip, Container } from '@pelotoncycle/design-system';
import React from 'react';

type Props = {
  dataTestId: string;
};

const CartTooltip: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  dataTestId,
}) => (
  <Tooltip
    thin
    trigger="hover"
    position="bottom"
    icon={{ primaryColor: slate[50], height: 16 }}
    margin={`${spacing[4]} 0 0 ${
      dataTestId == 'estimated-tax-tooltip' ? spacing[4] : spacing[8]
    }`}
    data-test-id={dataTestId}
    maxHeight="430px"
  >
    <Container textAlign="center" padding={spacing[12]}>
      {children}
    </Container>
  </Tooltip>
);

export default CartTooltip;
