import { spacing } from '@pelotoncycle/design-system';
import type { Ref } from 'react';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { PRODUCTION } from '@peloton/app-config';
import { media } from '@peloton/styles';
import { getMerchantId, getModalEnvironments } from './config';
import type { ZipModalProps } from './models';

export const ZipModalView = forwardRef(
  (props: ZipModalProps, ref?: Ref<HTMLDivElement>) => {
    const { price, width, bundleType } = props;
    const isProd = PRODUCTION as boolean | undefined;
    const merchantId = getMerchantId(isProd);
    const environments = getModalEnvironments(isProd);

    const dataAttrs = {
      'data-zm-widget': 'popup',
      'data-zm-region': 'au',
      'data-env': environments,
      'data-zm-merchant': merchantId,
      'data-zm-price': price,
      'data-zm-asset': 'productwidget',
      'data-zm-popup-asset': 'termsdialog',
    };

    return (
      <ZipModalContainer
        className="zip-modal-container"
        id={`${bundleType ? bundleType : 'default'}-zip-product-widget`}
        width={width}
        ref={ref}
        {...dataAttrs}
      />
    );
  },
);

ZipModalView.displayName = 'ZipModalView';

const ZipModalContainer = styled.div<{ width?: string }>`
  cursor: pointer;
  opacity: 0;
  position: absolute;
  float: left;
  visibility: none;
  width: ${props => props.width || '36px'};
  height: 18px;
  & > .zip-container {
    display: none;
  }
  &.zip-widget__wrapper {
    padding-top: ${spacing[8]};
  }
  ${media.smallScreen`
    &.zip-widget__wrapper {
      padding-top: 0;
    }
  `}
`;
