import { useCallback } from 'react';
import { useTracking } from 'react-tracking';
import type { CtCartFragment } from '@ecomm/shop-cart/graphql/fragments.generated';
import type { CartQuery } from '../graphql/queries/Cart.generated';
import {
  toSegmentProducts,
  toCategory,
  toName,
  toSkus,
  toSlugs,
  toCTCategory,
  toCTName,
  toCTSkus,
  toCTSegmentProducts,
  toCTSlugs,
} from './segmentMappings';

const toCTEvent = (cart: CtCartFragment, couponCode: string) => {
  const items = cart?.lineItems ?? [];
  const category = toCTCategory(items);
  const hasAccessory = category.includes('accessory');

  return {
    event: 'Coupon Applied',
    properties: {
      category,
      couponCode,
      hasAccessory,
      name: toCTName(items),
      price: cart?.priceSummaries?.cartPriceSummary?.subtotal?.centAmount ?? 0,
      products: toCTSegmentProducts(items),
      sku: toCTSkus(items),
      slug: toCTSlugs(items),
    },
  };
};

const toEvent = (cart: CartQuery['cart'], couponCode: string) => ({
  event: 'Coupon Applied',
  properties: {
    category: toCategory(cart),
    couponCode,
    hasAccessory: cart?.hasAccessories ?? false,
    hasGuide: cart?.hasGuide ?? false,
    name: toName(cart?.items ?? []),
    price: cart?.total?.amount ?? 0,
    products: toSegmentProducts(cart?.items ?? []),
    sku: toSkus(cart),
    slug: toSlugs(cart),
  },
});

type DataType = CtCartFragment | CartQuery['cart'] | undefined;

const usePromoCodeAnalytics = () => {
  const { trackEvent } = useTracking();

  const trackAppliedPromoCode = useCallback(
    (data: DataType, couponCode: string) => {
      let eventToTrack = {};
      const isShopCart = Boolean(data?.__typename) && data?.__typename === 'ShopCart';

      if (isShopCart) {
        eventToTrack = toCTEvent(data, couponCode);
      } else {
        eventToTrack = toEvent(data as CartQuery['cart'], couponCode);
      }

      trackEvent(eventToTrack);
    },
    [trackEvent],
  );

  return {
    trackAppliedPromoCode,
  };
};

export default usePromoCodeAnalytics;
