import { Eyebrow, spacing, Support } from '@pelotoncycle/design-system';
import React from 'react';

const Title: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <Eyebrow size="small" marginBottom={`${spacing[8]}`}>
      {children}
    </Eyebrow>
  );
};

const BodyText: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return <Support size="medium">{children}</Support>;
};

const infoMarkdownProps = {
  heading: Title,
  paragraph: BodyText,
};

export default infoMarkdownProps;
