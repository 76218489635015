import { Flex, spacing, grey, Label } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import useCartContent from '@ecomm/cart-next/shared/useCartContent';
import { getAffirm } from '@ecomm/financing/affirm/api';
import { AffirmLogo } from '@ecomm/logos';
import type { BundleType } from '@ecomm/shop/models';
import type { Term } from '../models';
import { Partners } from '../models';
import { trackClick } from '../utils/tracking';
import type { PageType } from './AffirmCopy';
import { LinkView } from './ModalLinkView';

type Props = {
  cfuBundleSKU?: string[];
  bundleType: BundleType;
  className?: string;
  pageType: PageType;
  price: number;
  term: Term;
  alignCenter?: boolean;
};

const AffirmCopyWithLogo: React.FC<React.PropsWithChildren<Props>> = ({
  alignCenter = false,
  cfuBundleSKU,
  bundleType,
  price,
  pageType,
  term,
}) => {
  const affirmLinkLabel = useCartContent('financingPricingAffirm');

  // Needed to refresh pricing in affirm iFrame
  // resource: https://docs.affirm.com/platforms/docs/quickstart#step-4-add-code-to-handle-price-changes
  React.useEffect(() => {
    const affirm = getAffirm();

    if (affirm?.ui) {
      affirm?.ui?.ready(function () {
        affirm.ui?.refresh();
      });
    }
  }, [price]);

  return (
    <StyledAffirmLink
      amount={price}
      cfuBundleSKU={cfuBundleSKU}
      bundleType={bundleType}
      term={term}
      wrapper="button"
      onClick={trackClick(Partners.Affirm, bundleType, pageType, price, true)}
    >
      {() => (
        <StyledContainer
          alignItems="baseline"
          verticalPadding="8px 0"
          alignCenter={alignCenter}
        >
          <Label size="medium">{affirmLinkLabel}</Label>
          <StyledAffirmLogo />
        </StyledContainer>
      )}
    </StyledAffirmLink>
  );
};

const StyledContainer = styled(Flex)<{ alignCenter: boolean; children: React.ReactNode }>`
  border-bottom: 1px solid ${grey[90]};
  max-width: fit-content;
  ${props => props.alignCenter && `margin: auto;`}
`;

const StyledAffirmLogo = styled(AffirmLogo)`
  width: ${spacing[40]};
  height: ${spacing[16]};
  margin-left: 6px;
`;

const StyledAffirmLink = styled(LinkView)`
  p {
    text-decoration: none !important;
    color: ${grey[90]} !important;
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.1px;
    line-height: ${spacing[16]};
  }

  div {
    border-bottom: 1.4px solid ${grey[90]} !important;
  }
`;

export default AffirmCopyWithLogo;
