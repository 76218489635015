import {
  Container,
  Flex,
  grey,
  Label,
  Support,
  spacing,
  black,
  red,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import useCart from '@content/client/www/cart/useCart';
import CartContent from '@ecomm/cart-next/shared/CartContent';
import useGetCartItems from '@ecomm/commercetools/hooks/useGetCartItems';
import FormatText from '@ecomm/copy/FormatText';
import { useTwoMonthsAAM } from '@ecomm/feature-toggle/TwoMonthsAAM';
import { toDollars } from '@ecomm/models';
import {
  hasCFUExceptGuideAndRefurbishedBike,
  isPrepaidMembership,
} from '../../helpers/ct-cart-helper';
import type { RemoveItemArgs } from './CartSummary';
import CartMarkdownContent from './shared/CartMarkdownContent';
import CartTooltip from './shared/CartTooltip';

type Props = {
  membershipItem: any;
  removeItem?: ({ id, isBundle, isBundleCFU }: RemoveItemArgs) => void;
  isFromFlyoutPanel?: boolean;
  quantity?: number;
};

const AllAccessMembershipView: React.FC<React.PropsWithChildren<Props>> = ({
  membershipItem,
  removeItem,
  isFromFlyoutPanel = true,
}) => {
  const id = membershipItem?.id ?? '';
  const name = membershipItem?.productVariant?.name ?? '';
  const description = membershipItem?.productVariant?.description ?? '';
  const quantity = membershipItem?.quantity ?? 0;
  const totalPrice = membershipItem?.priceSummary?.subtotal?.centAmount ?? 0;
  const { content: cartContent } = useCart();
  const isBundle = false;
  const isProductRemovable = isPrepaidMembership(membershipItem);
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && removeItem) {
      removeItem({ id, isBundle });
    }
  };
  const isTwoMonthsAAMPromoActive = useTwoMonthsAAM();
  const { data: cartItems } = useGetCartItems();
  const shouldDisplayAAMPromoText =
    hasCFUExceptGuideAndRefurbishedBike(cartItems?.lineItems ?? []) &&
    isTwoMonthsAAMPromoActive;

  return (
    <Container>
      <Flex
        justifyContent="space-between"
        flexDirection="column"
        flex-
        data-test-id="cart-product-details"
      >
        <FlexContainer isFromFlyoutPanel={isFromFlyoutPanel} quantity={quantity}>
          {quantity !== 0 && !isFromFlyoutPanel && (
            <StyledLabelQty size="large" data-test-id="qty">
              {quantity}
            </StyledLabelQty>
          )}
          <Flex
            flexGrow={1}
            data-test-id="cart-product-name-one"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Container>
              <StyledLabel
                isFromFlyoutPanel={isFromFlyoutPanel}
                quantity={quantity}
                data-test-id="item-name"
                size="large"
                textColor={black}
              >
                {!!name.length ? name : cartContent?.membershipName?.value}
              </StyledLabel>
              <StyledTooltipContainer>
                <CartTooltip dataTestId="tooltip">
                  <CartMarkdownContent
                    title={cartContent?.membershipInfoTitle?.value}
                    body={cartContent?.membershipInfoMsg?.value}
                  />
                </CartTooltip>
              </StyledTooltipContainer>
            </Container>
            {totalPrice !== 0 && !isFromFlyoutPanel && (
              <Label size="large" textColor={grey[90]} data-test-id="item-price">
                <FormatText
                  content="{price, number, currencyWithCents}"
                  values={{ price: toDollars(totalPrice) }}
                />
              </Label>
            )}
          </Flex>
          {quantity !== 0 && isFromFlyoutPanel && (
            <StyledLabelQty size="large" data-test-id="qty">
              <FormatText content={cartContent?.quantity?.value} values={{ quantity }} />{' '}
            </StyledLabelQty>
          )}
        </FlexContainer>
      </Flex>
      <StyledMembershipFlexContainer
        justifyContent="space-between"
        alignItems="flex-start"
        data-test-id="membership-content"
        isFromFlyoutPanel={isFromFlyoutPanel}
      >
        <Container maxWidth="256px">
          <StyledSupport size="small" textColor={grey[90]}>
            {description !== '' ? description : cartContent?.membershipDescription?.value}
          </StyledSupport>
          {shouldDisplayAAMPromoText && (
            <Container maxWidth={'256px'}>
              <Support data-test-id="freeAAMpromo" size="small" textColor={red[50]}>
                <CartContent field="freeAAMPromo" />
              </Support>
            </Container>
          )}
          {totalPrice !== 0 && isFromFlyoutPanel && (
            <Label size="large" textColor={grey[90]} data-test-id="item-price-mobile">
              <FormatText
                content="{price, number, currencyWithCents}"
                values={{ price: toDollars(totalPrice) }}
              />
            </Label>
          )}
        </Container>
        {isProductRemovable && removeItem && (
          <StyledRemoveLabel
            tabIndex={0}
            role="button"
            size="medium"
            textColor={grey[90]}
            is="span"
            data-test-id="remove-button-label"
            onClick={() => removeItem({ id, isBundle })}
            onKeyDown={e => handleKeyDown(e)}
          >
            {cartContent?.remove?.value}
          </StyledRemoveLabel>
        )}
      </StyledMembershipFlexContainer>
    </Container>
  );
};

export default AllAccessMembershipView;

const StyledMembershipFlexContainer = styled(Flex)<{
  isFromFlyoutPanel: boolean;
}>`
  margin-bottom: ${spacing[8]};
  ${props =>
    props.isFromFlyoutPanel ? `padding-left: 0` : `padding-left: ${spacing[16]}`}
`;
const FlexContainer = styled(Flex)<{
  isFromFlyoutPanel: boolean;
  quantity: number;
}>`
  margin-bottom: ${spacing[8]};
  ${props =>
    props.isFromFlyoutPanel && props.quantity == 0 && `justify-content: space-between`}
`;

const StyledTooltipContainer = styled.div`
  vertical-align: middle;
  display: inline-block;
  width: fit-content;
`;

const StyledRemoveLabel = styled(Label)`
  text-decoration: underline;
`;

const StyledLabel = styled(Label)<{
  isFromFlyoutPanel: boolean;
  quantity: number;
}>`
  display: inline;
  ${props =>
    props.isFromFlyoutPanel && props.quantity == 0
      ? `margin-left: 0`
      : !props.isFromFlyoutPanel && props.quantity > 0
      ? `margin-left: 0`
      : props.isFromFlyoutPanel && props.quantity > 0
      ? `margin-left: 0`
      : `margin-left: ${spacing[16]}`};
`;

const StyledSupport = styled(Support)`
  margin-bottom: ${spacing[8]};
`;
const StyledLabelQty = styled(Label)`
  margin-right: ${spacing[8]};
  padding-top: ${spacing[2]};
`;
