import { useHasVariation } from '@peloton/split-testing/hooks';

const MEMBERSHIP_LINE_ITEM_EXPERIMENT = 'Membership Line Item';
const MEMBERSHIP_LINE_ITEM_VARIATION = 'Variation #1';

const useIsMembershipLineItemExperimentActive = (isGift: boolean) => {
  const isInVariation = useHasVariation(
    MEMBERSHIP_LINE_ITEM_EXPERIMENT,
    MEMBERSHIP_LINE_ITEM_VARIATION,
  );
  return !isGift && isInVariation;
};

export default useIsMembershipLineItemExperimentActive;
