import React, { createContext, useCallback, useContext, useState } from 'react';

type Props = {
  isCartPanelOpen?: boolean;
  isDisabled?: boolean;
  isCartLoading?: boolean;
  hasItemJustBeenAdded?: boolean;
};

type CouponCodeError = {
  errorCode: string;
  couponCode: string;
};

const CartContext = createContext<{
  isCartPanelOpen: boolean;
  setIsCartPanelOpen: (_: boolean) => void;
  isDisabled: boolean;
  setIsDisabled: (_: boolean) => void;
  isCartLoading: boolean;
  setIsCartLoading: (_: boolean) => void;
  hasItemJustBeenAdded: boolean;
  setHasItemJustBeenAdded: (_: boolean) => void;
  cartException?: string;
  setCartException: (_: string | undefined) => void;
  couponCodeError?: CouponCodeError;
  setCouponCodeError: (_: CouponCodeError | undefined) => void;
  hasCouponAutoApplied: boolean;
  setHasCouponAutoApplied: (_: boolean) => void;
  hasBeenConverted: boolean;
  setHasBeenConverted: (_: boolean) => void;
}>({
  isCartPanelOpen: false,
  setIsCartPanelOpen: _ => undefined,
  isDisabled: false,
  setIsDisabled: _ => undefined,
  isCartLoading: false,
  setIsCartLoading: _ => undefined,
  hasItemJustBeenAdded: false,
  setHasItemJustBeenAdded: _ => undefined,
  cartException: undefined,
  setCartException: _ => undefined,
  couponCodeError: undefined,
  setCouponCodeError: _ => undefined,
  hasCouponAutoApplied: false,
  setHasCouponAutoApplied: _ => undefined,
  hasBeenConverted: false,
  setHasBeenConverted: _ => undefined,
});

export const CartProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  ...props
}) => {
  const [isCartPanelOpen, setIsCartPanelOpen] = useState(props.isCartPanelOpen ?? false);
  const [isDisabled, setIsDisabled] = useState(props.isDisabled ?? false);
  const [hasBeenConverted, setHasBeenConverted] = useState(false);
  const [isCartLoading, setIsCartLoading] = useState(props.isCartLoading ?? false);
  const [hasItemJustBeenAdded, setHasItemJustBeenAdded] = useState(
    props.hasItemJustBeenAdded ?? false,
  );
  const [cartException, setCartException] = useState<string>();
  const [couponCodeError, setCouponCodeError] = useState<CouponCodeError>();
  const [hasCouponAutoApplied, setHasCouponAutoApplied] = useState(false);

  return (
    <CartContext.Provider
      value={{
        isCartPanelOpen,
        setIsCartPanelOpen,
        isDisabled,
        setIsDisabled,
        isCartLoading,
        setIsCartLoading,
        hasItemJustBeenAdded,
        setHasItemJustBeenAdded,
        cartException,
        setCartException,
        couponCodeError,
        setCouponCodeError,
        hasCouponAutoApplied,
        setHasCouponAutoApplied,
        hasBeenConverted,
        setHasBeenConverted,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useIsCartPanelOpen = () => {
  return useContext(CartContext).isCartPanelOpen;
};

export const useOpenCartPanel = () => {
  const setIsCartPanelOpen = useContext(CartContext).setIsCartPanelOpen;

  return useCallback(() => {
    setIsCartPanelOpen(true);
  }, [setIsCartPanelOpen]);
};

export const useCloseCartPanel = () => {
  const setIsCartPanelOpen = useContext(CartContext).setIsCartPanelOpen;
  const setHasItemJustBeenAdded = useContext(CartContext).setHasItemJustBeenAdded;

  return useCallback(() => {
    setIsCartPanelOpen(false);
    setHasItemJustBeenAdded(false);
  }, [setHasItemJustBeenAdded, setIsCartPanelOpen]);
};

export const useIsCartPanelDisabled = () => {
  return useContext(CartContext).isDisabled;
};

export const useSetIsCartPanelDisabled = () => {
  const setter = useContext(CartContext).setIsDisabled;
  return (isDisabled: boolean) => setter(isDisabled);
};

export const useIsCartLoading = () => {
  return useContext(CartContext).isCartLoading;
};

export const useSetIsCartLoading = () => {
  const setter = useContext(CartContext).setIsCartLoading;
  return useCallback((isLoading: boolean) => setter(isLoading), [setter]);
};

export const useHasItemJustBeenAdded = () => {
  return useContext(CartContext).hasItemJustBeenAdded;
};

export const useSetHasItemJustBeenAdded = () => {
  const setter = useContext(CartContext).setHasItemJustBeenAdded;
  return useCallback((b: boolean) => setter(b), [setter]);
};

export const useCartException = () => {
  return useContext(CartContext).cartException;
};

export const useSetCartException = () => {
  const setter = useContext(CartContext).setCartException;
  return useCallback((exception: string) => setter(exception), [setter]);
};

export const useClearCartException = () => {
  const setter = useContext(CartContext).setCartException;
  return useCallback(() => setter(undefined), [setter]);
};

export const useCouponCodeError = () => {
  return useContext(CartContext).couponCodeError;
};

export const useSetCouponCodeError = () => {
  const setter = useContext(CartContext).setCouponCodeError;
  return useCallback((couponCodeError?: CouponCodeError) => setter(couponCodeError), [
    setter,
  ]);
};

export const useHasCouponAutoApplied = () => {
  return useContext(CartContext).hasCouponAutoApplied;
};

export const useHasBeenConverted = () => {
  return useContext(CartContext).hasBeenConverted;
};

export const useSetHasBeenConverted = () => {
  const setter = useContext(CartContext).setHasBeenConverted;
  return useCallback((hasBeenConverted: boolean) => setter(hasBeenConverted), [setter]);
};

export const useSetHasCouponAutoApplied = () => {
  const setter = useContext(CartContext).setHasCouponAutoApplied;
  return useCallback((b: boolean) => setter(b), [setter]);
};
