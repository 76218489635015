import {
  Flex,
  grey,
  Label,
  Support,
  spacing,
  Container,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import CartContent from '@ecomm/cart-next/shared/CartContent';
import CartMarkdownContent from '@ecomm/cart-next/shared/CartMarkdownContent';
import CartTooltip from '@ecomm/cart-next/shared/CartTooltip';
import infoMarkdownProps from '@ecomm/cart-next/shared/InfoMarkdownProps';
import useGetCartItems from '@ecomm/commercetools/hooks/useGetCartItems';
import { LegacyEquipmentType } from '../../helpers/ct-cart-helper';

type Props = { className?: string };

const GuideMembershipView: React.FC<React.PropsWithChildren<Props>> = ({ className }) => {
  const { data: shopCart } = useGetCartItems();
  const legacyEquipmentTypes =
    shopCart?.lineItems
      .map(item => item?.productVariant?.legacyEquipmentType)
      .filter(equipmentType => equipmentType !== null) ?? [];
  const hasOnlyGuideInCart =
    legacyEquipmentTypes.length > 0 &&
    legacyEquipmentTypes.every(
      equipmentType => equipmentType === LegacyEquipmentType.Guide,
    );

  return hasOnlyGuideInCart ? (
    <>
      <Divider />
      <Container className={className}>
        <Flex>
          <Label data-test-id="item-name" size="large">
            <CartContent field="guideMembershipName" />
          </Label>
          <CartTooltip dataTestId="tooltip">
            <CartMarkdownContent
              field="guideMembershipInfo"
              renderers={infoMarkdownProps}
            />
          </CartTooltip>
        </Flex>
        <Support size="small" textColor={grey[70]}>
          <CartContent field="guideMembershipDescription" />
        </Support>
      </Container>
    </>
  ) : null;
};

const Divider = styled.hr`
  border: none;
  background-color: ${grey[40]};
  height: 1px;
  margin-bottom: ${spacing[24]};
`;

export default GuideMembershipView;
