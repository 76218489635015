import { spacing } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { ZipLogo } from '@ecomm/logos';
import { toDollarsCeil } from '@ecomm/models';
import AddZipScriptToPage from './AddZipScriptToPage';
import type { ZipModalProps } from './models';
import { ZipModalView } from './ZipModalView';

const ZipLogoModule: React.FC<React.PropsWithChildren<ZipModalProps>> = ({
  price,
  bundleType,
  disableLoadZipScript = false,
}) => {
  return (
    <ZipLogoWrapper>
      {!disableLoadZipScript && <AddZipScriptToPage />}
      <ZipModalView bundleType={bundleType} key={price} price={toDollarsCeil(price)} />
      <ZipLogo ariaID={bundleType} />
    </ZipLogoWrapper>
  );
};

export default ZipLogoModule;

const ZipLogoWrapper = styled.div`
  display: inline-block;
  position: relative;
  height: 18px;
  width: 33px;
  margin-bottom: ${spacing[4]};
  ${media.smallScreen`
    padding-left: ${spacing[2]};
    margin-bottom: 0;
  `}
`;
