import { Label, spacing, Icon } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import useCartContent from '@ecomm/cart-next/shared/useCartContent';
import { Link } from '@ecomm/internationalize-ui';

const TruemedCopy: React.FC = () => {
  const financingPricingTruemedText = useCartContent('financingPricingTruemedText');
  const financingPricingTruemed = useCartContent('financingPricingTruemed');

  return (
    <>
      <StyledText>{financingPricingTruemedText}</StyledText>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link variant="primary">
        <Label size="medium">{financingPricingTruemed}</Label>
        <StyledIconWrapper>
          <Icon name="trueMed" height={12} />
        </StyledIconWrapper>
      </Link>
    </>
  );
};

export default TruemedCopy;

const StyledText = styled.div`
  margin-top: ${spacing[24]};
  margin-bottom: ${spacing[8]};
`;

const StyledIconWrapper = styled.div`
  margin-left: 6px;
  display: inline-block;
`;
