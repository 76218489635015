import {
  spacing,
  grey,
  slate,
  red,
  white,
  Flex,
  Label,
  Support,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import useCartContent from '@content/client/www/cart/useCart';
import useGetCartItems from '@ecomm/commercetools/hooks/useGetCartItems';
import FormatText from '@ecomm/copy/FormatText';
import { ShopCartUpdateActionType, DiscountType } from '@ecomm/graphql/types.generated';
import { toDollars } from '@ecomm/models';
import { getCountryCart } from '@ecomm/pg-checkout-commercetools/helpers/ct-cart-helper';
import { callUpdateCartMutation } from '@ecomm/pg-shop-accessories/quickAdd/cartHelper';
import { useUpdateShopCartJupiterMutation } from '@ecomm/shop-cart/graphql/mutations/UpdateCart.generated';

const StyledPromoCodeAppliedWrapper = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding-top: ${spacing[4]};
  color: ${grey[90]};
  ${media.desktopLarge`
    max-width: 560px;
  `}
`;
const StyledLabel = styled(Label)`
  display: inline-block;
  background-color: ${grey[90]};
  color: ${white};
  padding: ${spacing[2]} ${spacing[4]};
  text-transform: uppercase;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledSupport = styled(Support)`
  color: ${slate[50]};
  cursor: pointer;
  text-decoration: underline;
  margin-left: ${spacing[8]};
  margin-right: ${spacing[8]};
`;

const StyledDiscountAmount = styled(Label)`
  color: ${red[80]};
`;

const StyledDiscountDisplay = styled.div`
  display: flex;
  max-width: 70%;
`;

type Props = {
  allowRemove: boolean;
};

const PromoCodeAppliedSection: React.FC<React.PropsWithChildren<Props>> = ({
  allowRemove,
}) => {
  const { content: cartContent } = useCartContent();
  const { data: cartItems } = useGetCartItems();
  const discountData = cartItems?.discounts || [];
  const [updateCartMutation] = useUpdateShopCartJupiterMutation();

  const removeDiscountCoupon = async (id: string) => {
    const country = getCountryCart();
    const actions = [
      {
        removeDiscount: {
          id: id,
        },
        actionType: ShopCartUpdateActionType.RemoveDiscount,
      },
    ];
    try {
      await callUpdateCartMutation(actions, country, cartItems, updateCartMutation);
    } catch (e) {
      console.log(e);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent, removeId: string) => {
    if (event.key === 'Enter') {
      removeDiscountCoupon(removeId);
    }
  };

  return discountData.length ? (
    <>
      {discountData
        .filter(discount => discount.discountedValue)
        .map(discount => {
          const isAutoApplyDiscount =
            discount.discountType === DiscountType.AutoAppliedDiscount;
          const isB2BDiscount =
            discount.discountType === DiscountType.BusinessToBusinessDiscount;

          const discountDisplay =
            isAutoApplyDiscount || isB2BDiscount
              ? discount.name
              : discount.discountCode.code;

          const shouldShowRemoveOption = !isAutoApplyDiscount && allowRemove;

          return (
            <StyledPromoCodeAppliedWrapper key={discount.id}>
              <StyledDiscountDisplay>
                <StyledLabel size="small" data-test-id="discount-coupon-name">
                  {discountDisplay}
                </StyledLabel>
                {shouldShowRemoveOption && (
                  <StyledSupport
                    tabIndex={0}
                    role="button"
                    size="small"
                    data-test-id="discount-remove-button"
                    onClick={() => removeDiscountCoupon(discount.discountCode.id)}
                    onKeyDown={e => handleKeyDown(e, discount.discountCode.id)}
                  >
                    {cartContent?.remove?.value}
                  </StyledSupport>
                )}
              </StyledDiscountDisplay>
              <StyledDiscountAmount
                textColor={red[80]}
                data-test-id="coupon-discount-amount"
                size="large"
              >
                <FormatText
                  content="{price, number, currencyWithCents}"
                  values={{
                    price: toDollars(-discount.discountedValue?.centAmount ?? 0),
                  }}
                />
              </StyledDiscountAmount>
            </StyledPromoCodeAppliedWrapper>
          );
        })}
    </>
  ) : (
    <></>
  );
};

export default PromoCodeAppliedSection;
